<app-header></app-header>

<main id="main" class="full-wrap">
  <!-- loader -->
 <div *ngIf="lodTimer" id="overlay" style="height:100%;display:flex;align-items:center;justify-content:center;">
            <div style="font-size:7rem;" class="font-weight-bold text-warning">{{lodTimer}}</div>
        </div>
  <!-- internetDisscont -->
  <div *ngIf="!internetConn" class="col-12" style="margin-top: 38vh">
    <h4 class="font-weight-bold text-center text-warning">
      Please check your internet connection and try again
    </h4>
  </div>
  <!-- else  internetConnect-->
  <div *ngIf="internetConn" class="row m-0 market-page " >
    <div class="col-center col s12" style="margin-top: 6vh;padding:8px;margin-bottom: 8vh;" >

      <!---new design start-->
      <div class="market-page-inne">
        <div class="row">
          <div class="col-md-8">
            <div class="main-match-detail">

              <!---newdesign sk-->
              <div class="ballbyballleft-section" *ngIf="match_odds.length > 0 && manualMatchStatus">
                <div class="ballbyballvsec" style="background: rgb(52, 52, 53); color: rgb(255, 255, 255);">
                  <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#bts-modal" (click)="openbts(btopen)"
class="btsbtn">BTS</a>
                  <div class="ballbyballinfo">
                    <div class="ballbyballinfoinner">
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" color="#FFF"
                        height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="color: rgb(255, 255, 255);">
                        <path
                          d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z">
                        </path>
                      </svg>
                    </div>
                  </div>
                  <div class="ballbyballcounter">
                    <div class="bbbcounterinner"></div>
                  </div>
                  <div class="ballbyballcounter">
                    <div class="bbbcounterinnerone"></div>
                  </div>
                  <div class="bbbvideowrap"
                    style="">
                    <div class="bbbvideowrapiner">
                      <iframe class="bbbvideowrapvido" *ngIf="liveUrl && !bannerImg" id="fp_embed_player"
                        [src]="liveUrl" marginwidth="0" marginheight="0" frameborder="0" width="100%" scrolling="no"
                        allowfullscreen="allowfullscreen" allow="autoplay" disableremoteplayback playsinline muted
                        autoplay preload="auto"></iframe>

                      <div class="bannertimerbox">

                        <div *ngIf="bannerImg">
                          <img src="assets/images/Banner/ball_by_ball_banner.jpeg" width="100%">
                          <div class="animate__animated ">
                            <div *ngIf="matchDetail?.ballrun" class="rotate-down-bounce">
                              {{matchDetail?.ballrun}}</div>
                          </div>
                        </div>

                        <div *ngIf="mTimer" class="text-center font-weight-bold mt-1 timertext">
                          <span>00:{{ mTimer }}</span>
                        </div>
                      </div>

                      <!-- <video class="bbbvideowrapvido" preload="auto" autoplay="" disableremoteplayback="" playsinline=""
                        poster="https://bx-s3-dev-001.s3.ap-southeast-1.amazonaws.com/banner/ball_by_ball_banner.jpeg">
                        <source
                          src="https://bx-s3-dev-001.s3.ap-southeast-1.amazonaws.com/ball-video/1717664961969.mp4">Your
                        browser does not support the video tag.
                      </video> -->
                    </div>
                    <div class="bbbvideoinfo">
                      <div class="bbbvideoinfoiner">
                        <div class="bbbvideoinfotit">BALL BY BALL</div>
                        <div class="bbbvideoinforfid">Round ID: {{matchDetail?.marketId}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bbbgrid">
                  <div class="bbbgridcol-span-4" *ngFor="let x of runners_odds; trackBy: trackByFn;let i=index;">
                    <div *ngIf="checkMatchOddStatus(match_odds[0])" class="bbbgridcolsuspend">SUSPENDED</div>
                    <div class="bbbgridcol">
                      <div class="bbbgridcol-info">
                        <span style="color:#000">{{
                          searchRunner(
                          match_odds[0]?.runners,
                          x?.selectionId
                          )
                          }}</span>
                        <div class="bbbgridrunpt">
                          <span [ngClass]="{
                            redtext:
                              calProLoss(
                                  match_odds[0]?.runners,
                                  match_odds[0]?.marketBook,
                                i,
                                match_odds[0]
                              ) <= 0,
                            text_green:
                              calProLoss(
                                  match_odds[0]?.runners,
                                  match_odds[0]?.marketBook,
                                i,
                                match_odds[0]
                              ) > 0
                          }">{{
                            calProLoss(
                            match_odds[0]?.runners,
                            match_odds[0]?.marketBook,
                            i,
                            match_odds[0]
                            ) | number : "1.0-0"
                            }}.0</span>
                        </div>
                      </div>
                      <div class="bbbgridcolinforight">
                        <div class="bbbgrid-lay"
                          style="background: rgb(148, 223, 255); border-color: rgb(255, 255, 255);"
                          [ngClass]="x?.availableToBack?.price === x?.availableToBack?.oprice ? 'v-blue' : 'price_blinking' "
                          (click)="getOddsValue(x?.availableToBack?.price,x?.selectionId,match_odds[0]?.marketId,match_odds[0]?.marketName,match_odds[0]?.eventName,1,searchRunner(
                            match_odds[0]?.runners,
                            x?.selectionId
                            ),match_odds[0]?.marketType,0)">
                          <div class="bbbinfopt">{{x.availableToBack?.price}}</div>
                          <div class="bbbinfotbid">{{x.availableToBack?.size
                            | number : "1.0-0"}}</div>
                        </div>
                        <div class="infohiddesk">
                          <div>
                            <svg stroke="currentColor" fill="#6D081D" stroke-width="2" viewBox="0 0 24 24"
                              stroke-linecap="round" stroke-linejoin="round" color="#FFFFFF" class="w-[12px] ml-2"
                              height="12" width="12" xmlns="http://www.w3.org/2000/svg"
                              style="color: rgb(255, 255, 255);">
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="16" x2="12" y2="12"></line>
                              <line x1="12" y1="8" x2="12.01" y2="8"></line>
                            </svg>
                          </div>
                        </div>
                        <div class="bbbminmax">
                          <div class="bbbminmaxiner">
                            <div class="bbbmin">Min: 100</div>
                            <div class="bbbmax">Max: 25K</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!---newdesignend-->


            </div>
          </div>
          <div class="col-md-4">


            <div class="matchdetailsidebar">
              <div class="sk_md-sidebar">

                <div class="sk_widget">
                  <div class="sk_open-bet mobilhidebet">
                    <div class="widgettitle">
                      <h3 class="sk_opnbet-title">Open Bets</h3>
                    </div>

                    <div class="sk_open-bet-body">
                      <div class="sk_open-bet-content">
                        <select class="sk_open-bet-select">
                          <option value="">Select...</option>
                          <option value="ALL">ALL</option>
                        </select>
                        <!--<div class="sk_open-bet-tabs">
                          <div class="sk_open-bet-tableft">
                            <div (click)="bet_filter_type='Matched';filterBets();" class="sk_open-bet-tab-item"
                              [ngClass]="{'active':bet_filter_type==='Matched'}">
                              <span class="sk_tabaitem-title">Matched</span>
                            </div>
                            
                            <div (click)="bet_filter_type='Fancy';filterBets();" class="sk_open-bet-tab-item"
                              [ngClass]="{'active':bet_filter_type==='Fancy'}">
                              <span class="sk_tabaitem-title">Fancy</span>
                            </div>
                          </div>
                          <div class="sk_open-bet-tabright">
                            <input type="checkbox" class="mr-2 cursor-pointer" id="bet-info">
                            <label class="betinfolabel" for="bet-info">Bet Info</label>
                          </div>
                        </div> -->
                        <div class="table-responsive openbet-table-wrap mt-2">
                          <table class="openbet-table table">
                            <thead>
                              <tr>
                                <!-- <th>Btype</th> -->
                                <th>Nation</th>
                                <th>Odds</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngIf="filterBetData?.length>0">
                                <tr *ngFor="let bet of filterBetData" [ngClass]="{
                                  lay: bet?.type == 'Lay',
                                  back: bet?.type == 'Back'
                                }">
                                  <!-- <td> <span *ngIf="
                                  bet?.marketType === 'Special';
                                  else fancy_match_type
                                ">bookmaker</span>
                                    <ng-template #fancy_match_type>{{
                                      bet?.marketType
                                      }}</ng-template>
                                  </td> -->
                                  <!-- matchOdds/bookmaker -->
                                  <ng-container *ngIf="
                  bet?.marketType === 'MATCH_ODDS' ||
                    bet?.marketType === 'Special';
                  else else_session_block
                ">
                                    <td>{{ bet?.selectionName }}</td>
                                    <td class="right-text">
                                      <span *ngIf="
                      bet.marketType === 'Special';
                      else rate_matchOdds
                    ">{{ bet.rate * 100 - 100 | number : "0.0-2" }}</span>
                                      <ng-template #rate_matchOdds>{{
                                        bet?.rate | number : "0.0-2"
                                        }}</ng-template>
                                    </td>
                                  </ng-container>

                                  <!-- session -->
                                  <ng-template #else_session_block>
                                    <td>
                                      {{ bet?.marketName }} /
                                      {{ bet?.rate * 100 | number : ".0-2" }}
                                    </td>
                                    <td class="right-text">{{ bet?.selectionName }}
                                    </td>
                                  </ng-template>
                                  <!-- amount -->
                                  <td class="right-text">{{ bet?.stake }}</td>


                                </tr>
                              </ng-container>
                              <!-- notfound -->
                              <tr *ngIf="filterBetData?.length===0">
                                <td colspan="6">
                                  <div class="nodata font-weight-bold">No Data Found!
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- last result -->
                  <div class="sk_open-bet">
                    <div class="widgettitle">
                      <h3 class="sk_opnbet-title">Last Result</h3>
                    </div>

                    <!---NEw designright-->
                    <div class="lastresulrgrid">
                      <div class="lastresulrcol" *ngFor="let x of resultA;let i=index">
                        <div class="lastresulrttiel" (click)="scoreVideoModal(x)">{{ x.Result }}</div>
                      </div>

                    </div>
                    <!---NEw designright end-->


                  </div>
                </div>
              </div>
              <!---Sidebar end-->
            </div>
          </div>
        </div>
      </div>
      <!---new design End-->





      <!-- betPlace_modal -->


      <!--  -->
      <!-- ballbyballscore_modal -->
      <div class="modal fade" id="ballbyballpopupmodal-modal" tabindex="-1" data-backdrop="static"
        aria-labelledby="betpopupmodalmodalLabel">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5>{{modalresult}}</h5><a (click)="close_modal1();" href="javascript:void(0)"
                class="modal-action modal-close" aria-label="Close">
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="#FFFFFF" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="color: rgb(255, 255, 255);"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
              </a>
            </div>
            <div class="modal-body">
              <iframe *ngIf="modalscoreVideo" id="fp_embed_player" [src]="modalscoreVideo" marginwidth="0"
                marginheight="0" frameborder="0" width="100%" height="280px" scrolling="no"
                allowfullscreen="allowfullscreen" allow="autoplay" disableremoteplayback playsinline muted autoplay
                preload="auto"></iframe>
            </div>
          </div>
        </div>
      </div>

      
      <ng-template #btopen>
  <div class="modal-dialog">
    <div class="modal-content">
        <div class="sk_open-bet">
            <div class="modal-header model_header bg-warning">
                <h4 class="modal-title">Open Bets</h4>
                <a type="button" class="close" data-dismiss="modal">
                    <mat-icon (click)="modalRef.hide() ;">close</mat-icon>
                </a>
            </div>

            <div class="sk_open-bet-body">
              <div class="sk_open-bet-content">
                <select class="sk_open-bet-select">
                  <option value="">Select...</option>
                  <option value="ALL">ALL</option>
                </select>
                <!-- <div class="sk_open-bet-tabs">
                  <div class="sk_open-bet-tableft">
                    <div (click)="bet_filter_type='Matched';filterBets();" class="sk_open-bet-tab-item"
                      [ngClass]="{'active':bet_filter_type==='Matched'}">
                      <span class="sk_tabaitem-title">Matched</span>
                    </div>
                    <div (click)="bet_filter_type='Fancy';filterBets();" class="sk_open-bet-tab-item"
                      [ngClass]="{'active':bet_filter_type==='Fancy'}">
                      <span class="sk_tabaitem-title">Fancy</span>
                    </div>
                  </div>
                  <div class="sk_open-bet-tabright">
                    <input type="checkbox" class="mr-2 cursor-pointer" id="bet-info">
                    <label class="betinfolabel" for="bet-info">Bet Info</label>
                  </div>
                </div> -->
                <div class="table-responsive openbet-table-wrap mt-2">
                  <table class="openbet-table table">
                    <thead>
                      <tr>
                        <!-- <th>Btype</th> -->
                        <th>Nation</th>
                        <th>Odds</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="filterBetData?.length>0">
                        <tr *ngFor="let bet of filterBetData" [ngClass]="{
                          lay: bet?.type == 'Lay',
                          back: bet?.type == 'Back'
                        }">
                          <!-- <td> <span *ngIf="
                          bet?.marketType === 'Special';
                          else fancy_match_type
                        ">bookmaker</span>
                            <ng-template #fancy_match_type>{{
                              bet?.marketType
                              }}</ng-template>
                          </td> -->
                          <!-- matchOdds/bookmaker -->
                          <ng-container *ngIf="
          bet?.marketType === 'MATCH_ODDS' ||
            bet?.marketType === 'Special';
          else else_session_block
        ">
                            <td>{{ bet?.selectionName }}</td>
                            <td class="right-text">
                              <span *ngIf="
              bet.marketType === 'Special';
              else rate_matchOdds
            ">{{ bet.rate * 100 - 100 | number : "0.0-2" }}</span>
                              <ng-template #rate_matchOdds>{{
                                bet?.rate | number : "0.0-2"
                                }}</ng-template>
                            </td>
                          </ng-container>

                          <!-- session -->
                          <ng-template #else_session_block>
                            <td>
                              {{ bet?.marketName }} /
                              {{ bet?.rate * 100 | number : ".0-2" }}
                            </td>
                            <td class="right-text">{{ bet?.selectionName }}
                            </td>
                          </ng-template>
                          <!-- amount -->
                          <td class="right-text">{{ bet?.stake }}</td>


                        </tr>
                      </ng-container>
                      <!-- notfound -->
                      <tr *ngIf="filterBetData?.length===0">
                        <td colspan="6">
                          <div class="nodata font-weight-bold">No Data Found!
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>

</ng-template>


      <app-right-side-bar></app-right-side-bar>
    </div>
  </div>

  <app-footer></app-footer>
</main>