import { Component, OnInit , TemplateRef} from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {Clipboard} from '@angular/cdk/clipboard';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-wallet-idx',
  templateUrl: './wallet-idx.component.html',
  styleUrls: ['./wallet-idx.component.scss']
})
export class WalletIdxComponent implements OnInit {
  user: any;
  token: any;
  createid: any;
  myid:any;
  userdatak:any;
  IdDetail: any;
  MyIdDetail:any;
  MyIdDetails:any;
  modalRef: BsModalRef;
  constructor(
    private _location: Location,
    private router: Router,
    private usersService: UsersService,
    private modalService: BsModalService,
    private clipboard: Clipboard,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.user = sessionStorage.getItem('wallet-user');
   // console.log(this.user);
    this.userdatak =  JSON.parse(sessionStorage.getItem('userDetails'));
    this.token = sessionStorage.getItem('token');
    this.getcreateids();
   // alert('dddd');
    this.getmyid();
  }
  getcreateids(   ) {
    var data = {   user_id:this.userdatak.details._id}
    this.usersService.walletPost("getSite", data).subscribe((response: any) => {
      // console.log(response);
      this.createid = response.doc;
    })
  }
  getmyid() {
    const data={
      user_id:this.userdatak.details._id ,  
    }
    this.usersService.walletPost("getMysites", data).subscribe((response: any) => {
     // console.log(response);
      this.myid = response.data;
    })
  }
  gocreateid(id) {
    sessionStorage.setItem('createid',JSON.stringify(id))
    this.router.navigate(['create-id']);
  }
  backClicked() {
    // console.log('test')
    this._location.back();
  }
  gonotification() {
    this.router.navigate(['wallet-notifications']);
  }
  // gowithdraw(id) {
  //   sessionStorage.setItem('details',JSON.stringify(id))
  //   this.router.navigate(['deposite-withdraw']);
  // }

  gowithdraw(id,data) {
    sessionStorage.setItem('details',JSON.stringify(id))
    this.router.navigate(['deposite-withdraw/'+ data]);
  }

  IdDetaile(iddetaile: TemplateRef<any>, detailes) {
    this.IdDetail = detailes;
    // console.log(this.IdDetail);
    this.modalRef = this.modalService.show(
      iddetaile,
      Object.assign({}, { class: 'iddetaile modal-lg' })
    );
  }
  async copyToClip (value: string) {
    this.clipboard.copy(value);
    this.toastr.success("Copied !");
    
  };

  myiddetails(myiddetaile: TemplateRef<any>, detailes){
    this.MyIdDetail = detailes;
    // console.log(detailes);
    var data = {mysiteId: detailes._id, user_id:this.userdatak.details._id  }
    this.usersService.walletPost("getmysiteTransaction", data).subscribe((response: any) => {
      // console.log(response);
      this.MyIdDetails = response.doc;
    })
    this.modalRef = this.modalService.show(
      myiddetaile,
      Object.assign({}, { class: 'myiddetaile modal-lg' })
    );
  }

  // ngOnDestroy(){
  //   sessionStorage.removeItem('wallet-user');
  // }
}
