import { Component, HostListener, OnDestroy, OnInit,TemplateRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from '../services/users.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Socket } from 'ngx-socket-io';
import { SidenavService } from '../services/sidenav.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { PasswordStrengthValidator } from "../password-strength.validators"
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy {
  @ViewChild('changePwd', { static: true }) public privacyPopup: TemplateRef<any>;
  modalRef: BsModalRef;
  userDetails: any = [];
  walletBalance:number=0;
  exposure:number=0;
  casinoBal: number;
  hide: boolean = true;
  chngePass: number;
  submitted = false;
  tokenStatus: boolean = false;
  avaialableEventType: any;
  rollingBalance: any;
  ListData: any;
  offerstatment:any;
  myForms: FormGroup;
  logo: string;
  subscription: Subscription;
  loginButtnStatus:boolean=false;
  reg_symbol:boolean=true;
  @HostListener("document:visibilitychange",['$event'])
  visibilitychange(event:any) {
    if (!document.hidden){
      this.refresh();
} 
    }

  constructor(private router: Router,private sidenav: SidenavService, private usersService: UsersService,private socket: Socket,public _location: Location,private toastr: ToastrService,private sidenavService: SidenavService, private modalService: BsModalService, private fb: FormBuilder) 
    { 
        this.userDetails=JSON.parse(sessionStorage.getItem('userDetails'));
        this.usrLogOut();
        this.changeIcon();
    }

  ngOnInit(): void { 
    
    this.subscription =this.usersService.returnUserBalance().subscribe
      (data => { //message contains the data sent from service
        this.walletBalance = data.balance;
        this.exposure= data.exposure;
        // this.subscription.unsubscribe();
      });
     
    if(sessionStorage.getItem('loginStatus') === "true"){
      this.getUserBalance();
      this.loginButtnStatus=true;
    }
  }

  async findHostName()
    { 
      return (window.location.hostname);
    }

  async changeIcon() {
     const hostname=await this.findHostName();
     const splithostname= hostname.split('.')
     this.logo = splithostname[0];
     console.log(this.logo);
     
     if(this.logo==='paisaexch')
     {
       this.reg_symbol=false;
     }
  }


  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }

  }
  
   async getUserBalance() {
    this.userDetails = await this.getDetials();
    // console.log(this.userDetails.details.manager);   
    this.avaialableEventType = this.userDetails?.details?.availableEventTypes.indexOf("8080");
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('get-user', userdata);
      
      this.socket.on('get-user-success',(function(data:any){
        if(data){
          this.walletBalance = data.balance;
          this.exposure= data.exposure;
          // this.socket.removeListener('get-user-success');
        }
       }).bind(this));
      
    }

    RefreshBal() {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          token:this.userDetails.verifytoken,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },
        }
      };
      
      this.socket.emit('refresh-balance', userdata);
      setTimeout(()=>{ this.toastr.success('refresh balance', 'Success!');
   },700);
    }

  usrLogOut(){
    this.socket.on('logout',(function(data:any){
      this.logoutUser();
     }).bind(this));
  }

  
  inplay() {
    this.router.navigate(['/dashboard/inplay'])
  }

  refresh(): void {
    this.router.navigateByUrl("/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate([decodeURI(this._location.path())]);
    });
    if(sessionStorage.getItem('loginStatus') === "true"){
      this.getUserBalance();
    }
  }

  getWidth()
  {
   if(this.logo==='fairbets247')
   {
    return '50px';
   }
   else if(this.logo==='ferrariclubb')
   {
    return '70px';
   }
   else if(this.logo==='boomboombook')
   {
    return '140px';
   }
   else if(this.logo==='acepunt')
   {
    return '65px';
   }
   else
   {
    return '100px';
   }

  }


  logoutUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
    window.location.replace('login');
  }
//newuser
  createpassword() {
    this.myForms = this.fb.group({
      password: [null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])]
    });
  }

  passwordchange() {
    //  console.log(this.myForms.value);

  }

  onSubmitChangePassword() {
    this.submitted = true;

    if (this.myForms.value.password) {
      const userdata = {
        user: {
          _id: this.userDetails._id,
          key: this.userDetails.key,
          details: {
            username: this.userDetails.details.username,
            role: this.userDetails.details.role,
            status: this.userDetails.details.status,
          },

        },
        password: this.myForms.value.password,
        targetUser: '',
      };

      this.socket.emit('update-password', userdata);

      this.socket.on('update-password-success', (function (data: any) {

        if (data) {
          this.submitted = false;
          this.modalRef.hide();
          this.toastr.success(data.message, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          setTimeout(() => { this.logoutUser(); }, 1000);
        }
      }).bind(this));
    }
    else {
      this.toastr.error('new password is req');
    }

  }


  getCasinoBal() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    this.socket.emit('get-userbalance', userdata);

    this.socket.on('get-balance-success', (function (data: any) {

      if (data) {
        this.casinoBal = data.amount;
        // console.log(data);
      }
    }).bind(this));

  }
  downloadApk(){
    if(this.logo==='clubaob')
       window.open('https://bit.ly/3uXxx38', "_blank");
      else if(this.logo==='clubprt'){
        window.open('https://bit.ly/3PlW8H7', "_blank");
      }
      else if(this.logo==='clubosg'){
        window.open('https://bit.ly/3B0AtzK', "_blank");
      }
      else if(this.logo==='ferrariclubb'){
        window.open('https://bit.ly/3aVxM8j', "_blank");
      }
      else if(this.logo==='betx'){
        window.open('https://bit.ly/3OmN2Zf', "_blank");
      }
      else if(this.logo==='boomboombook'){
        window.open('https://bit.ly/44gLmJo', "_blank");
      }
      else if(this.logo==='dlexch'){
        window.open('https://bit.ly/3cCLnSe', "_blank");
      }
      else if(this.logo==='fairbets247'){
        window.open('https://bit.ly/3Ru5p0j', "_blank");
      }
      else if(this.logo==='paisaexch'||this.logo==='clubosg'||this.logo==='dubaiclub247')
      {
        window.open('https://bit.ly/3IY4Glj', "_blank");
      }
      else if(this.logo==='clublucky7')
      {
        window.open('https://bit.ly/3IhBLJD', "_blank");
      }
      else if(this.logo==='777exch')
        {
          window.open('https://bit.ly/4dNfvVH', "_blank");
        }
     
  }
  ngOnDestroy() {
    this.socket.removeListener('get-user-success');
    this.socket.removeAllListeners();
  }

}
