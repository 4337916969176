<mat-sidenav-container fullscreen class="pageledger">
  <mat-sidenav-content>
      <app-header></app-header>
      
      <div class="main-content mt-3">
          <!-- button -->
          <p class="text-center font-weight-bold">
              Days&nbsp;:&nbsp;&nbsp;&nbsp;
          <mat-button-toggle-group class="border border-primary rounded" name="fontStyle" aria-label="Font Style" [value]="days">
              <mat-button-toggle [value]=1 (change)="onValChange($event.value)">&nbsp;1&nbsp;</mat-button-toggle>
              <mat-button-toggle [value]=7 (change)="onValChange($event.value)">&nbsp;7&nbsp;</mat-button-toggle>
              <mat-button-toggle [value]=15 (change)="onValChange($event.value)">&nbsp;15&nbsp;</mat-button-toggle>
              <mat-button-toggle [value]=30 (change)="onValChange($event.value)">&nbsp;30&nbsp;</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          
         <!-- accordion -->
         <ng-container *ngFor="let x of ledgerList">
         <div [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'accGrnColor' : 'accRedColor'" class="mt-2 mx-2">

          <div class="card" style="background-color:#fff;">
            <div class="container-fluild">

            
            <div class="row">

              <div class="col-2 p-0">
                <div class="card-body text-success">
                  <img *ngIf="x.eventTypeId==4" src="assets/images/ledgerIcon/cricket.png" class="iconzo1">
                  <img *ngIf="x.eventTypeId==1" src="assets/images/ledgerIcon/soccer-player.png" class="iconzo1">
                  <img *ngIf="x.eventTypeId==2" src="assets/images/ledgerIcon/tennis.png" class="iconzo1">
                  <img *ngIf="x.eventTypeId=='c9'" src="assets/images/ledgerIcon/casino.png" class="iconzo1">
                  <img *ngIf="x.eventTypeId=='v9'" src="assets/images/ledgerIcon/virtual.png" class="iconzo2">
                  <img *ngIf="x.eventTypeId=='aviator'" src="assets/images/ledgerIcon/rocket.png" class="iconzo1">
                    <img *ngIf="x.eventTypeId=='b9'" src="assets/images/ledgerIcon/ball.png" class="iconzo1">
                  <img *ngIf="x.subAction=='BALANCE_DEPOSIT'" src="assets/images/ledgerIcon/deposit.png" class="iconzo1">
                  <img *ngIf="x.subAction=='BALANCE_WITHDRAWL'" src="assets/images/ledgerIcon/withdrawal.png"class="iconzo1">
                </div>
              </div>
              <div class="col-10 p-0">
               <b class="p-0"><span [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'text-success' : 'text-danger'"><span *ngIf="x?.subAction==='AMOUNT_WON'&& x?.action==='COMMISION' else ref">{{x.totalamount|number : '1.0-2'}} </span><ng-template #ref>{{x?.amount|number : '1.0-2'}}</ng-template></span><span style="float:right;">{{x.newLimit|number : '1.0-2'}}</span></b>
               <!-- Depo/Withd... -->
               <ng-container *ngIf="!x?.eventName">
                <p class="m-0"><span *ngIf="x.subAction=='BALANCE_DEPOSIT'">Deposite</span><span *ngIf="x.subAction=='BALANCE_WITHDRAWL'">Withdrawl</span></p>
                <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
               <p class="addnew m-0">{{x?.remark}}<mat-icon (click)="refer_log(ledgerBook,x)"> add </mat-icon></p>
               
               </ng-container>

                 <!-- Cricket/Soccer... -->
               <ng-container *ngIf="x?.eventTypeId !='v9'">
                <p class="m-0">{{x?.eventName}}</p>
                <p class="m-0">{{x?.marketName}}</p>
                <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
               </ng-container>
               <ng-container *ngIf="x?.eventTypeId =='v9'">
                <p class="m-0">{{x?.eventTypeName}}</p>
                <p class="m-0">{{x?.eventName}}</p>
                <p class="m-0">Old Limit:{{x?.oldLimit|number : '1.0-2'}} New Limit:{{x.newLimit|number : '1.0-2'}}</p>
               </ng-container>
               <p class="m-0">{{x.time|date:'medium'}}</p>
              
              </div>
            </div>
          </div>
           
          </div>
              <mat-accordion *ngIf="false" class="example-headers-align" multi>
                  <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header class="right-aligned-header">
                      <mat-panel-title >
                        <p>{{x?.eventTypeName}} </p>&nbsp; 
                        
                      </mat-panel-title>
                      <mat-panel-description>
                          <div class="font-weight-bold"><span [ngClass]="x?.subAction=='BALANCE_DEPOSIT' || x?.subAction=='AMOUNT_WON' ? 'text-success' : 'text-danger'">
                              {{x.amount|number : '1.0-2'}}&nbsp;&#8377;</span>&nbsp;&nbsp;&nbsp;{{x.newLimit|number : '1.0-2'}}
                              

                          </div>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <!-- <hr style="margin:0px;" [ngStyle]="x.subAction=='BALANCE_DEPOSIT' ?{'background': 'green'} : {'background': 'red'}"> -->
                    <!-- Depo/Withd... -->
                    <div *ngIf="!x?.eventName" class="row mt-2">
                      <div class="col-2 font-weight-bold">
                          {{x.newLimit|number : '1.0-2'}}
                          <p style="margin-bottom:0px;">{{x.amount|number : '1.0-2'}}</p>
                         
                            <!-- <span [ngStyle]="x.subAction=='BALANCE_DEPOSIT' ?{'c{{x.description}}olor': 'green'} : {'color': 'red'}"><span *ngIf="x.subAction=='BALANCE_DEPOSIT'">Deposite</span><span *ngIf="x.subAction=='BALANCE_WITHDRAWL'">Withdrawl</span></span> -->
                      </div>
                      <div class="col-10">
                         <span class="font-weight-bold">Description:&nbsp;</span>
                         {{x.time|date:'medium'}}
                      </div>
                    </div>

                    <!-- Cricket/Soccer... -->

                    <div *ngIf="x?.eventName" class="row mt-2">
                      <div class="col-4 font-weight-bold text-success">
                          {{x?.marketId}}<p>{{x?.eventId}}</p>
                      </div>
                      <div class="col-4 text-center font-weight-bold">
                        {{x?.eventName}}
                      </div>

                      <div class="col-4 font-weight-bold">
                        {{x.newLimit}}
                        <p style="margin-bottom:0px;">{{x.amount|number : '1.0-2'}}</p>
                          <span>{{x.marketName}}</span>
                      </div>

                      <div class="col-8">
                        <span class="font-weight-bold">Description:&nbsp;</span>{{x.description}}
                     </div>

                    </div>
                  
                  </mat-expansion-panel>
           </mat-accordion>
          </div>

         </ng-container>
         <div style="margin-top:15rem;font-weight:500;" class="text-center" *ngIf="ledgerList?.length==0">There is no data to display</div>
      </div>

      <ng-template #ledgerBook>
        <div class="modal-header model_header bg-warning">
            <h4 class="modal-title">Refer Event </h4>
            <a type="button" class="close" data-dismiss="modal">
                <mat-icon (click)="modalRef.hide()">close</mat-icon>
            </a>
        </div>
    
        <div class="modal-body" style="height:230px">
            <div class="table-div modeltable">
                <table class="table table-bordered">
                    <tr>
                      <th class="text-left" align="left">DateTime</th>
                        <th class="text-left" align="left">Event</th>
                        <th class="text-left" align="left">Market</th>
                        <th class="text-left" align="left">Amount</th>
                    </tr>
                    <tr style="color:'white'" *ngFor="let m of referLog?.ref_log;">
                       <td>
                            {{m.time|date:"medium"}}
                       </td>
                       <td>
                        {{m.eventName}}
                   </td>
                   <td>
                    {{m.marketName}}
               </td>
               <td>
                {{-1*m.settle_amount}}
           </td>
                    </tr>
                </table>
                <button style="float:right" class="modalbtn text-white" (click)="modalRef.hide()">Close</button>
            </div>
        </div>
    </ng-template>
      <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>