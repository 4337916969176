<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <app-header></app-header>

    <div class="main-content">

      <div class="row setmainhead">
        <div class="col-12">
          <h2 class="title">
            <mat-icon (click)="sidenavLeft.toggle()" class="order">menu</mat-icon>
            <span class="headicon"><mat-icon [routerLink]="['/wallet-notifications']">notifications</mat-icon></span>
          </h2>
        </div>



        <div class="new-design-wallet">
          <div class="wallet-col">
            <div class="deopwit-col">
              <span (click)="deposit()" class="inactive"> <img src="assets/images/arrow-up.svg">
                <br>Deposit</span>
            </div>
            <div class="deopwit-blnc-col">

              <h4>
                <span>Wallet Balance </span>
                <span *ngIf="userwalletBalance">{{userwalletBalance| number : '0.0-2'}}</span>
                <span *ngIf="!userwalletBalance">00</span>
              </h4>
              <h4>
                <span> Exposure </span>
                <span *ngIf="userwalletexposer">{{userwalletexposer| number : '0.0-2'}}</span>
                <span *ngIf="!userwalletexposer">00</span>
              </h4>
            </div>
            <div class="deopwit-col">
              <span (click)="withdraw()" class="inactive"> <img src="assets/images/arrow-down.svg">
                <br>withdraw</span>
            </div>
          </div>
        </div>


      </div>
      <div class="row setrow">
        <!-- <div class="col-3 text-center align"><span (click)="deposit()" class="inactive"><mat-icon>save_alt</mat-icon>
            <br>Deposit</span></div>
        <div class="col-3 text-center align"><span (click)="withdraw()" class="inactive"><mat-icon> money</mat-icon>
            <br>withdraw</span></div> -->
        <div class="col-6 text-center align"><span (click)="Ids()" class="inactive"><mat-icon>account_circle</mat-icon>
            <br>IDs</span></div>
        <div class="col-6 text-center align"><span [routerLink]="['/wallet-passbook']" class="inactive"><i
              class="material-icons">account_balance_wallet</i>
            <br>Passbook</span></div>
      </div>
      <div class="row mainsetrow">
        <div class="col-12">
          <h2>Related Links</h2>
        </div>
        <!-- <div class="col-3 text-center"><span (click)="goOffer()" class="inactive"><mat-icon>
              bubble_chart</mat-icon> <br>Offers</span></div> -->
        <div class="col text-center"><span [routerLink]="['/wallet-notifications']"
            class="inactive"><mat-icon>notifications_active</mat-icon>
            <br>Notifications</span></div>
        <div class="col text-center"><span  [routerLink]="['/wallet-withdraw-details']" class="inactive"><mat-icon>payment</mat-icon>
            <br>Withdraw Details</span></div>
        <div class="col text-center" *ngIf="support_nO"><a href="https://wa.me/{{support_nO}}"
            class="inactive"><mat-icon>perm_device_information</mat-icon>
            <br>whatsApp Support</a></div>
      </div>

      <div class="row mainsetrow1" *ngIf="transactions?.length!=0">
        <div class="col-12">
          <h2 class="setpenhed">Pending Request<span class="all" [routerLink]="['/wallet-passbook']">See All</span></h2>
          <div id="demo" class="carousel slide my-2" data-ride="carousel" *ngIf="transactions?.length!=0">
            <!-- The slideshow -->
            <div class="carousel-inner">
              <div class="carousel-item" [ngClass]="{'active': i == 0}"
                *ngFor="let tdata of transactions;let i = index" [routerLink]="['/wallet-passbook']">
                <div class="row setheadid">
                  <div class="col-3">
                    <div class="text-success">
                      <img *ngIf="!tdata.sites" src="assets/logo/logo_1.png" class="iconzo1">
                      <img *ngIf="tdata.sites"
                        src="https://adminapi.paisaexch.com/uploads/screenshot/{{tdata?.sites?.image}}" class="setimage">
                    </div>
                  </div>
                  <div class="col-9 setcolumn">
                    <b class="p-0" *ngIf="tdata.type!='Withdrawal'">
                      <span Class="settext1">{{tdata?.type}} <span> To {{tdata?.to}}</span></span>
                      <span
                        style="float:right;color: #000;letter-spacing: 1px;font-weight: 400;">{{tdata?.amount}}</span></b>
                    <b class="p-0" *ngIf="tdata.type=='Withdrawal'">
                      <span Class="settext1">{{tdata?.type}} <span> From {{tdata?.to}}</span></span>
                      <span
                        style="float:right;color: #000;letter-spacing: 1px;font-weight: 400;">{{tdata?.amount}}</span></b>
                    <p class="setremark1">
                      <!-- <span>{{tdata?.createdAt|date:'medium'}}</span> -->
                      <span>{{tdata?.createdAt|date:'d MMM yy h:mm a'}}</span>
                      <span  style="float:right;color: #00804a;" [ngClass]="tdata?.status == 'Approved' ? 'setlimit' : 'limitdanger'">{{tdata?.status}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="transactions?.length==0">
            <p class="setno">No Request Found</p>
          </div>
        </div>
      </div>


      <!-- <div class="row mainsetrow1" *ngIf="offerstatus">
        <div class="col-12">
          <h2>Offers <span class="all" (click)="deposit()">See All</span></h2>
          <div class="row firstoffer" *ngIf="offerstatus">
            <div class="col-3">
              <img class="setoimg" src="assets/images/offers.png">
            </div>
            <div class="col-9" (click)="deposit()">
              <h3>{{offerstatus?.name}}</h3>
              <p>Get {{offerstatus?.percentage}}% bonus {{offerstatus?.description}}</p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="row mainsetrow2" *ngIf="myid?.length!=0">
        <div class="col-12">
          <h2>My Ids <span class="all" (click)="Ids()">View All</span></h2>
        </div>
        <div class="col-12">
          <div id="demo" class="carousel slide my-2" data-ride="carousel">
            <!-- The slideshow -->
            <div class="carousel-inner">
              <div class="carousel-item" [ngClass]="{'active': i == 0}" *ngFor="let x of myid;let i = index">
                <div class="row setheadid">
                  <div class="col-2 text-center">
                    <img src="https://adminapi.paisaexch.com/uploads/screenshot/{{x?.sites?.image}}" alt="Chicago"
                      class="setimage">
                  </div>
                  <div class="col-10">
                    <a class="setpera" href="https://{{x?.sites?.url}}" target="_blank">{{x?.sites?.url}}&nbsp;
                      <span class="allicon"><i class='fas fa-external-link-alt fa-lg '></i></span>
                    </a>
                  </div>
                </div>
                <div class="row setheadid">
                  <div class="col-2 text-center">
                    <mat-icon class="setcopy">person</mat-icon>
                  </div>
                  <div class="col-10">
                    <p class="setpera">{{x?.username}}
                      <span (click)="copyToClip(x?.username)" class="all"><mat-icon
                          class="setcopy">file_copy</mat-icon></span>
                    </p>
                  </div>
                </div>
                <div class="row setheadid" *ngIf="hidehoure(x.createdAt)===true">
                  <div class="col-2 text-center">
                    <mat-icon class="setcopy">lock</mat-icon>
                  </div>
                  <div class="col-10">
                    <p class="setpera">{{x?.password}}
                      <span (click)="copyToClip(x?.password)" class="all"><mat-icon
                          class="setcopy">file_copy</mat-icon></span>
                    </p>
                  </div>
                </div>
                <div class="row setfoot">
                  <div class="col-6 text-center">
                    <span class="setdbtn" (click)="gowithdraw(x,'deposit')">Deposit</span>
                  </div>
                  <div class="col-6 text-center">
                    <span class="setwbtn" (click)="gowithdraw(x,'withdraw')">Withdraw</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="setsuport">
      <a href="https://wa.me/+919427953875">
        <img src="assets/payment/whatsapp.png" style="width:16%">
      </a>
    </div> -->
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-sidenav mode="side" #sidenavLeft closed position="start" (click)="sidenavLeft.toggle()" class="right-panel">
  <!-- <div class="sidebar-first-heading">
        <span><mat-icon>close</mat-icon></span>
        <button mat-stroked-button color="warn" (click)="logoutUser()">Logout <mat-icon>logout</mat-icon></button>
    </div> -->
  <div class="row user-profile align-items-center">
    <div class="col-xs-3">
      <img src="assets/images/user-circle.svg" />
    </div>
    <div class="col-xs-3 username">
      {{ user?.details?.username }}
      <div class="last-login">
      </div>
    </div>
  </div>

  <div class="row" style="margin: 5px 10px">
    <div class="winning-card">
      <a style="color: #323232">
        <img src="assets/icons/account_balance.png" />
        <div>Balance</div>
        <h5>
          <b>{{ walletBalance?.balance | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div>

    <!-- <div class="winning-card">
      <a style="color: #ffffff">
        <i class="material-icons">casino</i>
        <img src="assets/icons/events.png">
        <div>Casino</div>
        <h5>
          <b>{{ casinoBal | number : "0.0-2" }}</b>
        </h5>
      </a>
    </div> -->

    <div class="exposure-card">
      <img src="assets/icons/exposure.png" />
      <div>Exposure</div>
      <h5>
        <b>{{ walletBalance?.exposure | number : "0.0-2" }}</b>
      </h5>
    </div>
  </div>

  <div class="report-menu">
    <h4></h4>
    <ul class="menu-list">
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet']"><img src="assets/images/icon/home.png" /> Home</a>
      </li>
      <li>
        <a class="dropdown-item" (click)="deposit()"><img src="assets/images/wallet.svg" /> Deposit</a>
      </li>
      <li>
        <a class="dropdown-item" (click)="withdraw()"><img src="assets/images/wallet.svg" /> Withdraw</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-passbook']"><img src="assets/images/wallet.svg" /> Passbook</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-idx']"><img src="assets/images/icon/change_stack.png" />My
          Ids</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-notifications']"><img
            src="assets/images/icon/report.svg" />Notifications</a>
      </li>
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/wallet-offer']"><img src="assets/images/wallet.svg" /> Offers</a>
      </li> -->
      <li>
        <a class="dropdown-item" [routerLink]="['/wallet-withdraw-details']"><img
            src="assets/images/icon/user.svg" />Withdraw Details</a>
      </li>
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/referAcc']"
          ><img src="assets/images/wallet.svg" /> Refer Account</a
        >
      </li> -->
      <!-- <li>
        <a class="dropdown-item" [routerLink]="['/profile']"
          ><img src="assets/images/icon/user.svg" /> Profile</a
        >
      </li>
      <li>
        <a class="dropdown-item" [routerLink]="['/terms-condition']"
          ><img src="assets/images/icon/report.svg" /> Terms & Conditions</a
        >
      </li> -->
    </ul>
  </div>
</mat-sidenav>